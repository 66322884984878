.checkbox-container {
    display: flex;
    align-items: center;
  }
  
  .checkbox-container input[type="checkbox"] {
    display: none;
  }
  
  .checkbox-container label {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding-left: 25px;
    user-select: none;
  }
  
  .checkbox-container label:before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 2px solid #ff0000; /* Red border color */
    border-radius: 3px;
    background-color: white;
    position: absolute;
    left: 0;
  }
  
  .checkbox-container input[type="checkbox"]:checked + label:before {
    background-color: #ff0000; /* Red background color */
  }
  
  .checkbox-container input[type="checkbox"]:checked + label:after {
    content: "✔";
    position: absolute;
    left: 4px;
    top: -2px;
    font-size: 14px;
    color: white; /* White checkmark color */
  }
  
  .checkbox-container label span {
    margin-left: 8px;
  }
  
.App {
  display: flex;
  min-height: 100vh;
}
.main-content {
  width: 100%;
}
.content {
  flex: 1;
  background-color: #f9f9fc;
  overflow-y: auto;
  /* scrollbar-color: rgb(0, 0, 0) rgb(206, 30, 30); */
}
.form-input {
  display: flex;
  align-items: center;
}
.form-input > label {
   display: flex;
   flex-direction: column;
   gap: 10px;
   color: white;
}
.password-input-container > input {
    width: 516px;
    height: 46px;
    border-radius: 5px;
    background-color:  #121212;
    border: 1px solid rgb(107, 107, 107);
    padding-left: 10px;
    color: white;
    font-size: 15px;
}

.password-input-container {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  
  .password-toggle-button {
    position: absolute;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 14px;
    color: #007BFF;
  }
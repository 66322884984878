.modal-container {
  background-color: #f7f7fb !important;
  padding: 10px !important;
}

.modal-input-label {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 80px;
  font-weight: 700;
  margin: 20px;
  background-color: #f7f7fb;
}
.modal-input {
  width: 500px;
  height: 35px;
  border-radius: 5px;
  padding-left: 5px;
  border: 1px solid #c2c2c4;
  background-color: #ebecf6;
}
.modal-button {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  background-color: #644ded !important;
}

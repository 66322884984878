.login {
  background-color: #121212;
  height: 100vh;
}

.login-header {
  /* background-color: #121212; */
  padding: 20px 0 10px 70px;
  border-bottom: 1px solid #d11c1c;
}
.login-content {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 35px;
  padding-top: 80px;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.forget-password {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 30px;
}
.forget-password > a {
  color: #25639c;
}
.login-button {
  background-color: #d11c1c;
  width: 516px;
  height: 46px;
  border-radius: 5px;
  border: none;
  font-size: 17px;
  font-weight: 500;
  color: white;
  cursor: pointer;
}

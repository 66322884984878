.main-container {
  background-color: #ebecf6;
  display: flex;
  flex-direction: column;
  height: fit-content;
}
.header {
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.card-heading {
  font-weight: 600;
}
.link-tabs {
  display: flex;
  gap: 10px;
  padding-top: 10px;
}
.links {
  font-size: 13px;
  padding-right: 10px;
  border-right: 2px solid rgb(92, 88, 88);
  cursor: pointer;
}
.links:last-child {
  border-right: none;
}
.card-container {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
  /* border-top: 3px solid; */
  border-top: 3px solid rgba(128, 127, 127, 0.651);
}
.user-detail {
  margin-bottom: 5px;
}
.user-information,
.user-profile,
.user-location {
  flex: 1;
  padding: 20px;
}
.user-information,
.user-profile {
  border-right: 2px solid rgba(128, 127, 127, 0.651);
}

.detail-heading {
  font-size: 14px;
  font-weight: 700;
}
.detail-value {
  font-size: 11px;
  padding: 0;
  margin: 0;
}
.user-profile {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.user-profile img {
  border-radius: 50%;
}

.user-location {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.map-container {
  width: 100%;
  height: 31vh;
}


@media (max-width: 786px) {
  .card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .user-information,
  .user-profile {
    border-bottom: 2px solid rgba(128, 127, 127, 0.651);
    border-right: none;
  }
  .user-information,
  .user-profile,
  .user-location {
    width: 100%;
  }
  .link-tabs {
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px;
  }
  .links {
    flex-grow: 1;
    text-align: center;
    padding: 10px 10px;
    border-right: 2px solid rgb(92, 88, 88);
  }
  .map-container {
    width: 100%;
    height: 50vh;
  }
  

}

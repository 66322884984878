.network-container {
  background-color: #f9f9fc;
  padding: 10px;
}

.heading-section {
  padding: 10px;
  font-weight: 600;
}
.radio-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.radio-container > label {
  align-items: center;
}
.labelStyle {
  margin-left: 7px;
  font-weight: 600;
}
.container-1 {
  display: flex;
  flex-direction: row;
  gap: 70px;
  width: 50%;
}

.input-label {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.input {
  width: 500px;
  height: 25px;
  border-radius: 5px;
  padding-left: 5px;
  border: 0.5 solid black;
}
.input-container {
  display: flex;
  flex-direction: row;
  gap: 120px;
  margin-bottom: 15px;
}

.container-2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  width: 50%;
}
.container-2 > button {
  background-color: #644ded;
  border: none;
  color: white;
  font-size: 20px;
  font-weight: 700;
  width: 100px;
  border-radius: 5px;
  height: 35px;
  margin-top: 22px;
  cursor: pointer;
}

.connection-container {
  padding: 20px;
  border-top: 1px solid black;
}
.connection-detail {
  display: flex;
  flex-direction: row;
  padding: 10px;
  background-color: #ffffff;
  width: 800px;
  border: 1px solid rgba(82, 81, 81, 0.685);
}
.interface{
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.arr-button{
  border: none;
  background-color: white;
}